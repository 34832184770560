import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Remarkable } from 'remarkable';

import { getKey, isValidObject } from '../../../helpers';
import ContentCard from '../ContentCard';
import Text from '../../base/Text';

import Styles from './Event.styles';

const ContentWrapper = styled.div`
  ${Styles.contentWrapper}
`;

const ContentTitle = styled(Text)`
  ${Styles.contentTitle}
`;

const Content = styled.div`
  ${Styles.content}
`;

const MapLink = styled.a`
  ${Styles.mapLink}
`;

const MapEmbed = styled.div`
  ${Styles.mapEmbed}
`;

const EventLink = styled.a`
  ${Styles.eventLink}
`;

/**
 * Event Component
 * @returns {JSX}
 */
const EventComponent = ({
  backgroundImage,
  backgroundImageMobile,
  description,
  eventDay,
  eventMonth,
  eventYear,
  link,
  mapEmbed,
  mapLink,
  name,
  subTitle,
}) => {
  const md = new Remarkable({
    breaks: true,
    html: true,
  });
  const bgImage = getKey(backgroundImage, 'childImageSharp.fluid');
  const bgImageMobile = getKey(backgroundImageMobile, 'childImageSharp.fluid');

  const renderContent = useMemo(() => {
    if (!description) return null;
    return (
      <Content dangerouslySetInnerHTML={{ __html: md.render(description) }} />
    );
  }, [description, md]);

  return (
    <>
      <ContentCard
        backgroundImage={bgImage}
        backgroundImageMobile={bgImageMobile}
        title={name}
        content={`${eventDay} de ${eventMonth}, ${eventYear}`}
      />
      <ContentWrapper>
        <div className="container">
          <div className="row">
            {subTitle && <ContentTitle tag="h3">{subTitle}</ContentTitle>}
            {renderContent}
          </div>
          {
            isValidObject(link) && (
              <div className="row">
                <EventLink href={link.externalPage} target="_blank" rel="noopener noreferrer">
                  {link.label}
                </EventLink>
              </div>
            )
          }
          {
            mapLink && (
              <div className="row">
                <MapLink href={mapLink} target="_blank" rel="noopener noreferrer">MAPA</MapLink>
              </div>
            )
          }
          {
            mapEmbed && (
              <div className="row">
                <MapEmbed dangerouslySetInnerHTML={{ __html: mapEmbed }} />
              </div>
            )
          }
        </div>
      </ContentWrapper>
    </>
  );
};

EventComponent.propTypes = {
  backgroundImage: PropTypes.object,
  backgroundImageMobile: PropTypes.object,
  description: PropTypes.string,
  eventDay: PropTypes.string,
  eventMonth: PropTypes.string,
  eventYear: PropTypes.string,
  link: PropTypes.shape({
    externalPage: PropTypes.string,
    label: PropTypes.string,
  }),
  mapEmbed: PropTypes.string,
  mapLink: PropTypes.string,
  name: PropTypes.string,
  subTitle: PropTypes.string,
};

export default EventComponent;

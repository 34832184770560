import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { getKey } from '../helpers';
import EventComponent from '../components/compound/Event';


/**
 * Event page component
 * @param {Object} props of the component
 * @returns {JSX}
 */
const EventPage = props => {
  const content = getKey(props, 'data.allStrapiEvents.edges.0.node', {});
  const metaTitle = getKey(props, 'pageContext.metaTitle');
  return (
    <>
      {metaTitle && (
        <Helmet
          title={`Alestra - Eventos - ${metaTitle}`}
          meta={[
            { content: metaTitle, name: 'description' },
            { content: `${metaTitle}, alestra, eventos`, name: 'keywords' },
          ]}
        >
          <html lang="es" />
        </Helmet>
      )}
      <EventComponent {...content} />
    </>
  );
};

export default EventPage;

export const query = graphql`
  query EventsQuery($pageID: String!) {
    allStrapiEvents(filter: {id: {eq: $pageID}}) {
      edges {
        node {
          id
          name
          slug
          description
          eventDay: date(formatString: "D")
          eventMonth: date(formatString: "MMMM", locale: "es")
          eventYear: date(formatString: "YYYY")
          mapEmbed
          mapLink
          subTitle
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          link {
            externalPage
            label
            id
          }
        }
      }
    }
  }
`;

import { css } from 'styled-components';
import {
  WHITE,
  MID_GRAY,
  CERULEAN,
} from '../../../constants/colors';
import { rem, media } from '../../../helpers/mixins';

export default {
  content: css`
    color: ${MID_GRAY};
    font-size: ${rem(15)};
    line-height: ${rem(24)};

    ${media.md`
      font-size: ${rem(17)};
      line-height: ${rem(25)};
    `}

    h2, h3, h4 {
      color: ${CERULEAN};
      font-weight: 700;
      margin-bottom: 20px;

      ${media.md`
        margin-bottom: 30px;
      `}
    }

    h3 {
      ${media.md`
        font-size: ${rem(21)};
        line-height: ${rem(29)}
      `}
    }

    ol, ul {
      padding-left: 30px;
    }

    li {
      margin-bottom: 10px;
    }
  `,
  contentTitle: css`
    display: block;
    font-size: ${rem(21)};
    margin-bottom: 30px;
    width: 100%;

    &::after {
      background: ${CERULEAN};
      content: ' ';
      display: block;
      height: 1px;
      margin-top: 15px;
      width: 100%;
    }
  `,
  contentWrapper: css`
    background-color: ${WHITE};
    padding-bottom: 40px;
    padding-top: 40px;
  `,
  eventLink: css`
    background: ${CERULEAN};
    border-radius: 100px;
    color: ${WHITE};
    display: inline-block;
    font-size: ${rem(14)};
    font-weight: 700;
    line-height: ${rem(32)};
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
  `,
  mapEmbed: css`
    width: 100%;

    > iframe {
      width: 100%;
    }
  `,
  mapLink: css`
    color: ${CERULEAN};
    display: block;
    font-weight: 800;
    margin-bottom: 16px;
    margin-top: 16px;
  `,
};
